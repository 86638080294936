<template>
  <div class="RefundInformation">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn2">
          <span style="height: 40px; line-height: 40px">选手名称：</span>
          <el-input
            style="margin-right: 20px; width: 200px"
            v-model="playerNames"
          ></el-input>
          <el-button
            style="height: 40px; margin-left: 10px"
            type="primary"
            plain
            @click="searchAwardLogisticsList"
            >查询</el-button
          >
        </div>
      </div>
      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="600"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="收件人" width="400">
            <template slot-scope="scope">
              <span>{{ scope.row.username }}</span>
            </template>
          </el-table-column>
          <el-table-column label="收件人电话" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.phone }}</span>
            </template>
          </el-table-column>
          <el-table-column label="收件省市" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.area }}</span>
            </template>
          </el-table-column>
          <el-table-column label="详细地址" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.address }}</span>
            </template>
          </el-table-column>
          <el-table-column label="物流公司" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.logisticsCompany }}</span>
            </template>
          </el-table-column>
          <el-table-column label="快递单号" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.logisticsNo }}</span>
            </template>
          </el-table-column>

        </el-table>
      </div>
      <div class="page-card">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: this.$route.params.back,
      unitAwardId: this.$route.params.id,
      activeName: '1',
      tab: {},
      name: '',
      currentPage: 1,
      pageSize: 10,
      totalCount: 0,
      tableData: [],
    };
  },
  methods: {
    dialogClose() {},
    handleSizeChange(val) {
      this.pageSize = val;
      this.findAwardLogisticsList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.findAwardLogisticsList();
    },
    //获取所有信息
    async findAwardLogisticsList() {
      try {
        const result = await this.$api.awardLogisticsList(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}&unitAwardId=${this.unitAwardId}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.totalCount = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    },
    //查询信息
    async searchAwardLogisticsList() {
      this.currentPage = 1;
      this.pageSize = 10;
      try {
        const result = await this.$api.awardRecordInfo(
          `currentPage=${this.currentPage}&pageSize=${this.pageSize}&competitionId=${this.competitionId}&unitAwardId=${this.unitAwardId}`
        );
        console.log(result);
        this.tableData = result.pageResults;
        this.totalCount = result.totalCount;
      } catch (error) {
        console.log(error);
      }
    }
  },

  created() {
    this.findAwardLogisticsList();
  },
};
</script>

<style lang="less">
.RefundInformation {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn {
        display: flex;
        // float: right;
        .select {
          width: 100px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
      .btn2 {
        display: flex;
      }
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 150px;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
